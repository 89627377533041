import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-dialog-layout',
  imports: [CommonModule],
  templateUrl: './dialog-layout.component.html',
  standalone: true,
})
export class DialogLayoutComponent implements OnDestroy {
  @Input() public showFooter = true;
  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'dialog-open');
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'dialog-open');
  }
}
