<section class="dialog-layout--container">
  <div class="dialog-layout--content">
    <ng-content select="[content]"></ng-content>
  </div>

  <div *ngIf="showFooter" class="dialog-layout--footer">
    <div class="buttons">
      <ng-content select="[buttons]"></ng-content>
    </div>
  </div>
</section>
